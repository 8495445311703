<template>
  <WidgetFrame @onResize="onResize" v-bind:class="sizeClass" contentClass="overflow-unset">
    <template v-slot:title>
      {{ $t('dashboard.budget_vs_actual') }}
    </template>
    <template v-slot:content>
      <div class="input-group project-budget-actual-input-group">
        <div class="input-group-prepend">
          <label class="input-group-text" for="budgetType">
            {{$t('dashboard.widgets.view_by')}}
          </label>
        </div>
        <multiselect v-model="type" class="custom-dropdown-options enable-option-icon"
          :max-height="300"
          :options="optionTypes.map(i => i.key)"
          :custom-label="getTypeOptionLabel"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="type == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ getTypeOptionLabel(props.option) }}</span>
          </template>
        </multiselect>
      </div>
      <div class="summary-text" :style="{ marginTop: '10px' }">
        <template v-if="notStarted">
          <i class="far fa-minus-square" :style="{ color: 'var(--status-orange)' }"></i> Not Started
        </template>
        <template v-else-if="over">
          <i class="fas fa-triangle-exclamation" :style="{ color: 'var(--status-red)' }"></i> Over Budget
        </template>
        <template v-else-if="under">
          <i class="far fa-circle-check" :style="{ color: 'var(--status-green)' }"></i> Under Budget
        </template>
        <template v-else-if="good">
          <i class="far fa-circle-check" :style="{ color: 'var(--status-green)' }"></i> On Budget
        </template>
      </div>
      <div class='bva-chart-container'>
        <div class='chart-item'>
          <div class='chart-title'>
            {{ $t('dashboard.total_budget') }}: <span>{{ totalBudgetFormat }}</span></div>
          <div id="TOTAL_BUDGET" class='chart-bar'>
            <div class='chart-bar-progress' :style="{ width: progress+'%', backgroundColor: 'var(--status-orange)' }"></div>
          </div>
          <b-popover
            target="TOTAL_BUDGET"
            placement="top"
            triggers="hover"
            :content="`${$t(type === 'net' ? 'dashboard.total_budget_net' : 'dashboard.total_budget')}: ${totalBudgetFormat}`">
          </b-popover>
          <div v-if="fixedDuration && canView('PROJECT', ['fixedDuration'])" class="text-center mt-2">
            {{ $t('dashboard.budgeted_duration') }}: {{ budgetedDurationFormat }}
          </div>
        </div>
        <div class='chart-item'>
          <div class='chart-title'>
            <span>{{ $t('dashboard.estimated_cost') }}:</span>
            <span>{{ estimatedCostFormat }}</span>
          </div>
          <div id="ESTIMATED_COST" class='chart-bar'>
            <div class='chart-bar-progress' :style="{ width: estimatedProgress+'%', backgroundColor: 'var(--status-light-blue)' }"></div>
          </div>
          <b-popover
            target="ESTIMATED_COST"
            placement="top"
            triggers="hover">
            {{ `${$t(type === 'net' ? 'dashboard.estimated_cost_net' : 'dashboard.estimated_cost')}: ${estimatedCostFormat}` }}
            <br/>
            <span v-if="estimatedCost !== 0">{{ $t('dashboard.margin', [margin]) }}</span>
          </b-popover>
          <div class="text-center mt-2">
            {{ $t('dashboard.estimated_duration') }}: {{ estimatedDurationFormat }}
          </div>
        </div>
        <div class='chart-item'>
          <div class='chart-title'>
            <span>{{ $t('dashboard.planned_budget') }}:</span>
            <span>{{ plannedBudgetFormat }}</span>
          </div>
          <div id="PLANNED_BUDGET" class='chart-bar'>
            <div class='chart-bar-progress' :style="{ width: plannedProgress+'%', backgroundColor: 'var(--status-blue)' }"></div>
          </div>
          <b-popover
            target="PLANNED_BUDGET"
            placement="top"
            triggers="hover">
            {{ `${$t(type === 'net' ? 'dashboard.planned_budget_to_date_net' : 'dashboard.planned_budget_to_date')}: ${plannedBudgetFormat}` }}
            <br/>
            {{ `${plannedOfTotal}% ${$t(type === 'net' ? 'dashboard.of_total_budget_net' : 'dashboard.of_total_budget')}` }}
            <br/>
            {{ `${plannedOfEstimated}% ${$t(type === 'net' ? 'dashboard.of_estimated_costs_net' : 'dashboard.of_estimated_costs')}` }}
          </b-popover>
        </div>
        <div class='chart-item'>
          <div class='chart-title'>
            <span>{{ $t('dashboard.actual_budget') }}:</span>
            <span>{{ actualBudgetFormat }}</span>
          </div>
          <div id="ACTUAL_BUDGET" class='chart-bar'>
            <div class='chart-bar-progress' :style="{ width: actualProgress+'%', backgroundColor: over ? 'var(--status-red)' : 'var(--status-green)' }"></div>
          </div>
          <b-popover
            target="ACTUAL_BUDGET"
            placement="top"
            triggers="hover">
            {{ `${$t(type === 'net' ? 'dashboard.actual_budget_to_date_net' : 'dashboard.actual_budget_to_date')}: ${actualBudgetFormat}` }}
            <br/>
            {{ `${actualOfPlanned}% ${$t(type === 'net' ? 'dashboard.of_planned_budget_to_date_net' : 'dashboard.of_planned_budget_to_date')}` }}
          </b-popover>
          <div class="text-center mt-2">
            {{ $t('dashboard.actual_duration') }}: {{ actualDurationFormat }}
          </div>
        </div>
      </div> 
    </template>
  </WidgetFrame>
</template>

<script>
import WidgetFrame from "@/components/Dashboard/WidgetFrame";
import { costFormat, costFormatAdv } from '@/helpers';
import { convertMinutesToDays } from '@/helpers';
import { extractDurationConversionOpts } from '@/helpers/task-duration-process';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ProjectBudgetActualWidget',
  components: {
    WidgetFrame,
    Multiselect
  },
  props: {
    profile:    { type: Object, required: true },
    project:    { type: Object, required: true }
  },
  watch: {
    type(newValue, oldValue) {
      if (oldValue != null) {
        this.saveSettings();
        this.buildData();
      }
    },
    project() {
      // Budget values could have changed with the project, so rebuild view
      this.buildData();
    }
  },
  computed: {
    optionTypes() {
      return [
        {key: 'gross', label: this.$t('dashboard.gross_costs')},
        {key: 'net', label: this.$t('dashboard.net_costs')},
      ]
    },
    sizeClass: function () {
      let size = {};
      if (this.width < 200) {
        size['XSM'] = true;
      }
      if (this.width < 380) {
        size['SM'] = true;
      }
      return size;
    },
    margin() {
      if (this.totalBudget === 0) {
        return 0;
      }
      const diff = this.totalBudget - this.estimatedCost;
      return Math.round(diff / this.totalBudget * 100);
    },
    plannedOfTotal() {
      if (this.totalBudget === 0) {
        return 0;
      }
      return Math.round(this.plannedBudget / this.totalBudget * 100);
    },
    plannedOfEstimated() {
      if (this.estimatedCost === 0) {
        return 0;
      }
      return Math.round(this.plannedBudget / this.estimatedCost * 100);
    },
    actualOfPlanned() {
      if (this.plannedBudget === 0) {
        return 0;
      }
      return Math.round(this.actualBudget / this.plannedBudget * 100);
    },
    totalBudgetFormat() {
      return this.currencyCode == null? `$${costFormat(this.totalBudget)}` : costFormatAdv(this.totalBudget, this.currencyCode);
    },
    budgetedDurationFormat() {
      return `${convertMinutesToDays(this.project.fixedDuration ? this.project.fixedDuration : 0, this.durationConversionOpts)} ${this.$t('button.days')}`;
    },
    estimatedCostFormat() {
      return this.currencyCode == null? `$${costFormat(this.estimatedCost)}` : costFormatAdv(this.estimatedCost, this.currencyCode);
    },
    actualBudgetFormat() {
      return this.currencyCode == null? `$${costFormat(this.actualBudget)}` : costFormatAdv(this.actualBudget, this.currencyCode);
    },
    plannedBudgetFormat() {
      return this.currencyCode == null? `$${costFormat(this.plannedBudget)}` : costFormatAdv(this.plannedBudget, this.currencyCode);
    },
    estimatedDurationFormat() {
      return `${convertMinutesToDays(this.project.estimatedDuration ? this.project.estimatedDuration : 0, this.durationConversionOpts)} ${this.$t('button.days')}`;
    },
    actualDurationFormat() {
      return `${convertMinutesToDays(this.project.actualDuration ? this.project.actualDuration : 0, this.durationConversionOpts)} ${this.$t('button.days')}`;
    },
  },
  data() {
    return {
      width: null,
      height: null,
      // Budget state, only one can be true
      notStarted: false,
      over: false,
      under: false,
      good: false,
      // $ values
      totalBudget: 0,
      actualBudget: 0,
      plannedBudget: 0,
      estimatedCost: 0,
      fixedDuration: null,
      // Progress bar values, highest $ value = 100%
      progress: 0,
      actualProgress: 0,
      plannedProgress: 0,
      estimatedProgress: 0,
      type: null,
      currencyCode: null,
      durationConversionOpts: {}
    };
  },
  created() {
    this.getDurationConversionOpts();
    // Use 'gross' default type if profile doesn't have a valid key
    const pType = this.profile.optionType;
    this.type = !(this.optionTypes.map(t => t.key).includes(pType)) ? 'gross' : pType;
    this.buildData();
  },
  methods: {
    buildData() {
      this.notStarted = false;
      this.over = false;
      this.under = false;
      this.good = false;
      this.costOver = false;
      this.currencyCode = this.project.currencyCode != null && this.project.currencyCode.trim().length > 0? this.project.currencyCode : null;
      this.fixedDuration = this.project.fixedDuration;
      
      if (this.type == "gross") {
        this.totalBudget = this.project.fixedCost ? this.project.fixedCost : this.project.estimatedCost ? this.project.estimatedCost : 0;
        this.estimatedCost = this.project.estimatedCost ? this.project.estimatedCost : 0;
        this.plannedBudget = this.project.plannedCost ? this.project.plannedCost : 0;
        this.actualBudget = this.project.actualCost ? this.project.actualCost : 0;
        this.plannedProgress = this.project.plannedProgress ? this.project.plannedProgress : 0;
      } else {
        this.totalBudget = this.project.fixedCostNet ? this.project.fixedCostNet : this.project.estimatedCostNet ? this.project.estimatedCostNet : 0;
        this.estimatedCost = this.project.estimatedCostNet ? this.project.estimatedCostNet : 0;
        this.plannedBudget = this.project.plannedCostNet ? this.project.plannedCostNet : 0;
        this.actualBudget = this.project.actualCostNet ? this.project.actualCostNet : 0;
        this.plannedProgress = this.project.plannedProgress ? this.project.plannedProgress : 0;
      }
      
      // // Quick tests
      // this.totalBudget = 100;
      // this.estimatedCost = 150;
      // this.plannedBudget = 80;
      // this.actualBudget  = 85;
      

      // Find which bar gets to be 100%, and make the rest relative to it.
      if (this.totalBudget >= this.actualBudget && this.totalBudget >= this.plannedBudget && this.totalBudget >= this.estimatedCost) {
        this.progress = 100;
        this.estimatedProgress = (this.estimatedCost / this.totalBudget) * 100;
        this.actualProgress = (this.actualBudget / this.totalBudget) * 100;
        this.plannedProgress = (this.plannedBudget / this.totalBudget) * 100;
      } else if (this.estimatedCost >= this.actualBudget && this.estimatedCost >= this.totalBudget && this.estimatedCost >= this.plannedBudget ) {
        this.progress = (this.totalBudget / this.estimatedCost) * 100;
        this.estimatedProgress = 100;
        this.actualProgress = (this.actualBudget / this.estimatedCost) * 100;
        this.plannedProgress = (this.plannedBudget / this.estimatedCost) * 100;
      } else if (this.actualBudget >= this.totalBudget && this.actualBudget >= this.plannedBudget && this.actualBudget >= this.estimatedCost) {
        this.progress = (this.totalBudget / this.actualBudget) * 100;
        this.estimatedProgress = (this.estimatedCost / this.actualBudget) * 100;
        this.actualProgress = 100
        this.plannedProgress = (this.plannedBudget / this.actualBudget) * 100;
        
      } else {
        this.progress = (this.totalBudget / this.plannedBudget) * 100;
        this.estimatedProgress = (this.estimatedCost / this.plannedBudget) * 100;
        this.actualProgress = (this.actualBudget / this.plannedBudget) * 100;
        this.plannedProgress = 100;
        // But, if the planned progress is actually 0 (project hasn't started),
        // then we prefer to keep the bar empty.
        if (this.plannedBudget == 0) {
          this.plannedProgress = 0;
        }
      }

      // Percentage difference
      var diff;
      if (this.plannedBudget == 0) {
        // It will always be over budget, so let's at least make it a percentage of the total
        // value. E.g. current budget being 8% of the total budget = on budget, 12% = over
        diff = (this.actualBudget / this.totalBudget) * 100
      } else {
        // If the planned budget is more than the total budget, use the total as the planned.
        let planned = this.plannedBudget;
        if (this.plannedBudget > this.totalBudget) {
          planned = this.totalBudget;
        }
        diff = 100 - ((this.actualBudget / planned) * 100)
      }

      if (this.plannedBudget == 0 && this.actualBudget == 0) {
        this.notStarted = true;
      } else if ( diff >= -10 && diff <= 10) {
        // 10% tolerance for 'good'
        this.good = true;
      } else if (diff < 10) {
        this.over = true;
      } else {
        this.under = true;
      }

      if (this.estimatedCost > this.totalBudget) {
        this.costOver = true;
      }
    },
    saveSettings() {
      this.profile.optionType = this.type;
      this.$emit('saveWidget', this.profile);
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        console.error(e); //eslint-disable-line no-console
      });
    },
    getTypeOptionLabel(value) {
      return this.optionTypes.find(i => i.key === value)?.label || value;
    },
  }
}
</script>

<style lang="scss" scoped>
  .bva-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }
  .chart-item {
    border: 1px solid var(--border-medium);
    padding: 10px 20px;
    border-radius: 4px;
  }
  .chart-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }


  /* Responsive styles */
  .SM .chart-title-hint {
    display: none;
  }
  .SM .chart-title {
    font-size: 12px;
  }

  .XSM .chart-title {
    font-size: 10px;
  }
  .XSM .chart-item {
    padding: 8px;
  }
  .XSM .chart-bar {
    height: 20px;
  }
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    class: _vm.sizeClass,
    attrs: { contentClass: "overflow-unset" },
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(" " + _vm._s(_vm.$t("dashboard.budget_vs_actual")) + " "),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "input-group project-budget-actual-input-group" },
              [
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c(
                    "label",
                    {
                      staticClass: "input-group-text",
                      attrs: { for: "budgetType" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dashboard.widgets.view_by")) + " "
                      ),
                    ]
                  ),
                ]),
                _c("multiselect", {
                  staticClass: "custom-dropdown-options enable-option-icon",
                  attrs: {
                    "max-height": 300,
                    options: _vm.optionTypes.map((i) => i.key),
                    "custom-label": _vm.getTypeOptionLabel,
                    placeholder: "",
                    searchable: false,
                    "allow-empty": false,
                    showLabels: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _vm.type == props.option
                            ? _c("font-awesome-icon", {
                                staticClass: "selected-option-icon",
                                attrs: { icon: ["far", "check"] },
                              })
                            : _vm._e(),
                          _c("span", { staticClass: "option__title" }, [
                            _vm._v(
                              _vm._s(_vm.getTypeOptionLabel(props.option))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "summary-text", style: { marginTop: "10px" } },
              [
                _vm.notStarted
                  ? [
                      _c("i", {
                        staticClass: "far fa-minus-square",
                        style: { color: "var(--status-orange)" },
                      }),
                      _vm._v(" Not Started "),
                    ]
                  : _vm.over
                  ? [
                      _c("i", {
                        staticClass: "fas fa-triangle-exclamation",
                        style: { color: "var(--status-red)" },
                      }),
                      _vm._v(" Over Budget "),
                    ]
                  : _vm.under
                  ? [
                      _c("i", {
                        staticClass: "far fa-circle-check",
                        style: { color: "var(--status-green)" },
                      }),
                      _vm._v(" Under Budget "),
                    ]
                  : _vm.good
                  ? [
                      _c("i", {
                        staticClass: "far fa-circle-check",
                        style: { color: "var(--status-green)" },
                      }),
                      _vm._v(" On Budget "),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "bva-chart-container" }, [
              _c(
                "div",
                { staticClass: "chart-item" },
                [
                  _c("div", { staticClass: "chart-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("dashboard.total_budget")) + ": "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.totalBudgetFormat))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "chart-bar", attrs: { id: "TOTAL_BUDGET" } },
                    [
                      _c("div", {
                        staticClass: "chart-bar-progress",
                        style: {
                          width: _vm.progress + "%",
                          backgroundColor: "var(--status-orange)",
                        },
                      }),
                    ]
                  ),
                  _c("b-popover", {
                    attrs: {
                      target: "TOTAL_BUDGET",
                      placement: "top",
                      triggers: "hover",
                      content: `${_vm.$t(
                        _vm.type === "net"
                          ? "dashboard.total_budget_net"
                          : "dashboard.total_budget"
                      )}: ${_vm.totalBudgetFormat}`,
                    },
                  }),
                  _vm.fixedDuration && _vm.canView("PROJECT", ["fixedDuration"])
                    ? _c("div", { staticClass: "text-center mt-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("dashboard.budgeted_duration")) +
                            ": " +
                            _vm._s(_vm.budgetedDurationFormat) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "chart-item" },
                [
                  _c("div", { staticClass: "chart-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dashboard.estimated_cost")) + ":"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.estimatedCostFormat))]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "chart-bar",
                      attrs: { id: "ESTIMATED_COST" },
                    },
                    [
                      _c("div", {
                        staticClass: "chart-bar-progress",
                        style: {
                          width: _vm.estimatedProgress + "%",
                          backgroundColor: "var(--status-light-blue)",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "ESTIMATED_COST",
                        placement: "top",
                        triggers: "hover",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$t(
                              _vm.type === "net"
                                ? "dashboard.estimated_cost_net"
                                : "dashboard.estimated_cost"
                            )}: ${_vm.estimatedCostFormat}`
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm.estimatedCost !== 0
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("dashboard.margin", [_vm.margin]))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "text-center mt-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("dashboard.estimated_duration")) +
                        ": " +
                        _vm._s(_vm.estimatedDurationFormat) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "chart-item" },
                [
                  _c("div", { staticClass: "chart-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dashboard.planned_budget")) + ":"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.plannedBudgetFormat))]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "chart-bar",
                      attrs: { id: "PLANNED_BUDGET" },
                    },
                    [
                      _c("div", {
                        staticClass: "chart-bar-progress",
                        style: {
                          width: _vm.plannedProgress + "%",
                          backgroundColor: "var(--status-blue)",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "PLANNED_BUDGET",
                        placement: "top",
                        triggers: "hover",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$t(
                              _vm.type === "net"
                                ? "dashboard.planned_budget_to_date_net"
                                : "dashboard.planned_budget_to_date"
                            )}: ${_vm.plannedBudgetFormat}`
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.plannedOfTotal}% ${_vm.$t(
                              _vm.type === "net"
                                ? "dashboard.of_total_budget_net"
                                : "dashboard.of_total_budget"
                            )}`
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.plannedOfEstimated}% ${_vm.$t(
                              _vm.type === "net"
                                ? "dashboard.of_estimated_costs_net"
                                : "dashboard.of_estimated_costs"
                            )}`
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "chart-item" },
                [
                  _c("div", { staticClass: "chart-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dashboard.actual_budget")) + ":"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.actualBudgetFormat))]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "chart-bar",
                      attrs: { id: "ACTUAL_BUDGET" },
                    },
                    [
                      _c("div", {
                        staticClass: "chart-bar-progress",
                        style: {
                          width: _vm.actualProgress + "%",
                          backgroundColor: _vm.over
                            ? "var(--status-red)"
                            : "var(--status-green)",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "ACTUAL_BUDGET",
                        placement: "top",
                        triggers: "hover",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$t(
                              _vm.type === "net"
                                ? "dashboard.actual_budget_to_date_net"
                                : "dashboard.actual_budget_to_date"
                            )}: ${_vm.actualBudgetFormat}`
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.actualOfPlanned}% ${_vm.$t(
                              _vm.type === "net"
                                ? "dashboard.of_planned_budget_to_date_net"
                                : "dashboard.of_planned_budget_to_date"
                            )}`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "text-center mt-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("dashboard.actual_duration")) +
                        ": " +
                        _vm._s(_vm.actualDurationFormat) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }